body {
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;
}

.logo {
  font-family: 'Lobster', cursive;
  letter-spacing: 0.2rem;
  font-weight: bold;
}

#root {
  width: 100%;
  height: 100%;
}
